<template>
<body class="menu-position-side menu-side-left full-screen with-content-panel">
    <div ui-view autoscroll="true" style="min-height: 100%;" class="ng-scope">
        <div class="all-wrapper menu-side with-pattern ng-scope" style="padding: 0px !important; background: #09b585; background-size: 100% 100%;" ng-class="{'d-none' : emailSent}">
            <div class="auth-box-w" style="margin-top: 40px">
                <div class="logo-w">
                    <h3 class="h3">Cyruspay</h3>
                </div>
                <h4 class="auth-header">Two-Factor Authentication</h4>
                <form name="login_form" v-on:submit.prevent="login()" action="" method="post" class="ng-pristine ng-invalid ng-invalid-required">
                    <p>Provide your authentication code in the field below to complete sign in.</p>
                    <div class="form-group text-left">
                        <v-app>
                            <v-col class="format-input" cols="0" sm="12" md="12" style="padding-left: 0px">
                                <v-text-field autofocus v-model="verifyCode" label="Verification code" 
                                style="height: 40px" type="text" name="verifyCode" 
                                class="input-group--focused" required outlined
                                autocomplete="off"></v-text-field>
                            </v-col>
                        </v-app>
                    </div>
                    <div class="form-group">
                        <input v-model="isCheckRemember" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="isCheckRemember" >
                        <label class="form-check-label" for="isCheckRemember">
                            Remember me
                        </label>
                    </div>
                    <div class="row">
                        <div class="col text-left">
                            <button class="btn btn-primary" type="submit" ng-disabled="loading">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                Verify
                            </button>
                        </div>
                        <div class="form-check-inline">
                            <router-link ui-sref="login" :to="'/login'+query">Go to log in</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</body>
</template>

<script>
import * as External from '@/utils/External.js'
import * as Logs from "@/utils/Logs";
import * as Session from "@/utils/SessionStore"
import ApiManager from "@/api/ApiManager"

import {
    CHECK_CODE_ENCODE,
    LOGIN,
    USER_INFO,
    ADD_ACCOUNTANT,
    SAVE_DEVICE
} from "@/store/actionsType";

export default {
    name: "ForgotPassword",
    mounted() {
        External.head_link("/assets/css/tt/styleRegister.css");
        External.head_link("/assets/css/tt/themeRegister.css");
        External.head_link("/assets/css/tt/mainLogin.css");
        External.head_link("/assets/css/tt/style(1)Login.css");
        External.head_link("/assets/css/tt/rocket.css");
        // Logs.stringKey("djsldfc")
        // Logs.json("router",this.$route)
        // Logs.json("params",this.$route.params)
        if(this.$route.params.userName == undefined || this.$route.params.password == undefined) {
            this.$router.push({
                path: "/login"
            });
        }
    },
    destroyed() {
        External.del_link("/assets/css/tt/styleRegister.css");
        External.del_link("/assets/css/tt/themeRegister.css");
        External.del_link("/assets/css/tt/mainLogin.css");
        External.del_link("/assets/css/tt/style(1)Login.css");
        External.del_link("/assets/css/tt/rocket.css");
    },
    data() {
        return {
            verifyCode: "",
            isCheckRemember: false,
            isInvitationAccountant: false,
            code: "",
            companyId: "",
            query: ""
        };
    },
    created: function () {
        if(this.$route.params.isInvitationAccountant) {
            this.code = this.$route.params.code
            this.companyId = this.$route.params.companyid
            this.query = "?companyid="+this.companyId+"&code="+this.code
            this.checkCodeEncode();
        }
    },
    methods: {
        checkCodeEncode(){
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(CHECK_CODE_ENCODE, this.code)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    if(!response.result){
                        this.$router.push({
                            path: "/link-expired"
                        });
                    } else {
                        this.isInvitationAccountant = true
                    }
                }).catch((err) => {
                    Logs.logError('Error in checkCodeEncode', err)
                    this.$root.$refs.AppCyrus.finish()
                })
        },
        login() {
            var token2FA = ""
            if(Session.has('2FAToken')) {
                token2FA = Session.get('2FAToken')
            }
            var request = {
                "userName": this.$route.params.userName,
                "passwordHash": this.$route.params.password,
                "code2FA": this.verifyCode,
                "remembere2FAToken": token2FA,
                "remembere2FA": this.isCheckRemember
            }
          
            Logs.logDebug('request', request)
            Session.set('isLogin', true)
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(LOGIN, request)
            .then ((response) => {
                if(response != undefined) {
                    if(this.isInvitationAccountant) {
                        var dataRequest = {
                            codeEncode: this.code,
                            companyId: this.companyId,
                            email: this.$route.params.userName
                        }
                        this.$root.$refs.AppCyrus.start()
                        this.$store.dispatch(ADD_ACCOUNTANT, dataRequest)
                            .then(() => {
                                this.$root.$refs.AppCyrus.finish()
                                this.successHandling(response)
                            }).catch(() => {
                                this.$root.$refs.AppCyrus.fail()
                                this.errorHandling()
                            })
                    } else {
                        this.successHandling(response)
                    }
                }
            }).catch(() => {
                this.$root.$refs.AppCyrus.fail()
                this.errorHandling()
            });
        },
        successHandling(accuracy) {
            this.$store.dispatch(USER_INFO)
                .then((response) => {
                Session.set('isLogin', false)
                this.$root.$refs.AppCyrus.finish()
                Session.remove("currenCompanyId")
                Session.remove("currenCompanyName")
                Session.set("Username", response.lastName)
                var date = new Date()
                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)
                date.setMilliseconds(0)
                Session.set("dateLogin", date)
                Session.set("Profile", {
                    'id': response.id,
                    "firstName": "",
                    'lastName': response.lastName,
                    "birthDate": date,
                    "email": this.$route.params.userName,
                    "role": response.role,
                    "creationTime": response.creationTime
                })
                Session.set("2FAToken", accuracy.remembere2FAToken)
                // Logs.string('token2fa', Session.get("2FAToken"))
                this.$store.dispatch(SAVE_DEVICE, response.id)
                    if (this.isInvitationAccountant || accuracy.isSetupCompany) {
                        this.$router.push({
                            path: "/dashboard"
                        });
                    } else {
                        this.$router.push({
                            name: "company",
                            params: {
                                isNotBack: true
                            }
                        });
                    }
            }).catch(() => {
                this.errorHandling()
            })
        },
        errorHandling() {
            Session.set('isLogin', false)
            if(Session.get('hasGetHandshake')) {
                ApiManager.getHandShake().then(() => {
                    Session.set('hasGetHandshake', false)
                    this.login()
                }).catch(() => {
                    if (this.$global.path != '/updateserver') {
                        this.$modal.show('modal-tryhandshake', {
                            data: ''
                        });
                    }
                });
            }
        },
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/company.css';
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.pagination {
    justify-content: center;
    text-align: center;
}
</style>
